import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ButtonBar from "./ButtonBar"
import Option from "./Option"
import QuestionIcon from "../../resources/img/ui/icon-question-mark.svg"
import ReactTooltip from 'react-tooltip';

const Question = styled.div`
  ${fonts.garamondRegular};
  margin: 20px auto 0;
  font-size: 20px;
  line-height: 1.36;
  max-width: 600px;
  p{
  
  }
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 30px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 30px;
    font-size: 22px;
  }
`

const ChoiceInputContainer = styled.div``

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${(props) => (props.subtext ? "30px" : "45px")};
  @media (max-width: 400px) and (max-height: 600px) {
    margin-top: 20px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 30px;
  }
  @media (min-width: ${breakpoints.md}) and (min-height: 800px) {
    margin-top: 60px;
  }
`

const SubText = styled.div`
  margin: 15px auto 0;
  font-size: 14px;
  line-height: 1.38;
  max-width: 220px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 25px;
  }
`
const WhyWeAsk = styled.span`
  a{
    vertical-align: middle;
    font-size: 18px;
  }
  img{
    width: 16px;
  }
`

const ChoiceInput = (props) => {
  const {
    options,
    subtext,
    quizAnswers,
    setQuizAnswers,
    trackingLabel,
    selectionsIdentifier,
    dynamicQuestion,
    questionWeight,
    handleNext,
    active,
  } = props

  const [popupOpen, setPopupOpen] = useState(false)
  const [enter, setEnter] = useState(false)
  const [selected, setSelected] = useState([])
  const [skipTo, setSkipTo] = useState(null)
  const skipToWeightChart = {}

  options.forEach((option) => {
    if (option.skipToQuestion) {
      let chartKeys = Object.keys(skipToWeightChart)
      if (!chartKeys.includes(option.skipToQuestion)) {
        skipToWeightChart[option.skipToQuestion] =
          option.skipToQuestionWeight || 0
      }
    }
  })

  let sectionRef = useRef(null)

  const getSkipTo = (answers) => {
    const selectedAnswer = answers.reduce((prev, current) => {
      if (!current.skipToWeight) {
        current.skipToWeight = 0
      }
      return current.skipToWeight > prev.skipToWeight ? current : prev
    })

    return selectedAnswer.skip
  }

  const handleAnswerUpdate = (value, skip, speedBump) => {
    let answers = { ...quizAnswers }
    let isSelected = selected.some((answer) => answer.value === value)
    let updatedSelected = [...selected]

    if (selected.length === 0) {
      updatedSelected = [{ value, skip, skipToWeight: skipToWeightChart[skip] }]
    } else {
      if (isSelected) {
        updatedSelected = selected.filter((answer) => answer.value !== value)
      } else {
        updatedSelected = [
          ...selected,
          { value, skip, skipToWeight: skipToWeightChart[skip] },
        ]
      }
    }

    answers[trackingLabel] = updatedSelected.map((answer) => answer.value)

    let skipToAnswer = null

    if (updatedSelected.length > 0) {
      skipToAnswer = getSkipTo(updatedSelected)
    }

    if (speedBump && !isSelected) {
     
      setPopupOpen(true)
    }

    setSkipTo(skipToAnswer)
    setSelected(updatedSelected)
    setQuizAnswers(answers)
  }

  const checkEnterKey = (e) => {
    e.preventDefault()
    if (e.keyCode === 13) {
      if (!enter) {
        setEnter(true)
      }
    }
  }

  useEffect(() => {
    if (sectionRef) {
      sectionRef.addEventListener("keyup", checkEnterKey)
    }

    return () => {
      if (sectionRef) {
        sectionRef.removeEventListner("keyup", checkEnterKey)
      }
    }
  })

  useEffect(() => {
    if (enter) {
      const isEmail = trackingLabel === "Email"
      handleNext(false, isEmail, trackingLabel, selectionsIdentifier)
    }
  }, [enter])

  return (
    <React.Fragment>
      <ChoiceInputContainer ref={(el) => (sectionRef = el)}>
        <Question>
          {dynamicQuestion} 
        {props.whyWeAsk && props.whyWeAsk.childMarkdownRemark.html !== "" && (
          <WhyWeAsk>
            <a data-tip={props.whyWeAsk.childMarkdownRemark.html}> <img src={QuestionIcon} /> </a>
            <ReactTooltip place="bottom" type="dark" effect="solid" html={true} />
          </WhyWeAsk>
        )}
        </Question>
        {subtext && <SubText>{subtext}</SubText>}
        <Options subtext={subtext ? 1 : 0}>
          {options.map((option, index) => {
            const { text, speedBumpText, speedBumpExit, speedBumpAccept } =
              option
            const value = text.split(" ").join("-").toLowerCase()
            const speedBump = speedBumpAccept && speedBumpExit && speedBumpText
            const isChecked =
              quizAnswers[trackingLabel] &&
              quizAnswers[trackingLabel].includes(value)

            return (
              <Option
                {...option}
                value={value}
                speedBump={speedBump}
                isChecked={isChecked}
                key={index}
                aria-label={value}
                handleAnswerUpdate={handleAnswerUpdate}
                questionWeight={questionWeight}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
              />
            )
          })}
        </Options>
      </ChoiceInputContainer>
      <ButtonBar {...props} skip={skipTo} />
    </React.Fragment>
  )
}

export default ChoiceInput
