import React, { useState, useEffect, useRef } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import ButtonBar from "./ButtonBar"
import QuestionIcon from "../../resources/img/ui/icon-question-mark.svg"
import ReactTooltip from 'react-tooltip';

const Question = styled.div`
  ${fonts.garamondRegular};
  margin-top: 20px;
  font-size: 20px;
  line-height: 1.36;
  p{

  }
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 30px;
  }

  @media (min-width: ${breakpoints.md}) {
    margin: auto;
    margin-top: 30px;
    font-size: 22px;
    line-height: 1.36;
    max-width: 600px;
  }
`

const TextInputContainer = styled.div`
  input {
    width: 100%;
    background: transparent;
    border: none;
    text-align: center;
    border-bottom: 1px solid ${colors.charcoal};
    padding: 1px 2px 8px;
    ${fonts.garamondRegular};
    font-size: 22px;
    line-height: 1.15;
    max-width: 360px;
    margin: 90px auto 30px;
    outline: none;
    border-radius:0;
    opacity: ${(props) => (props.active ? 1 : 0.5)};
    ::placeholder {
      opacity: 0.5;
      letter-spacing: normal;
    }
    ::-webkit-input-placeholder {
      opacity: 0.5;
    }
    ::-moz-placeholder {
      opacity: 0.5;
    }
    :-ms-input-placeholder {
      opacity: 0.5;
    }
    :-moz-placeholder {
      opacity: 0.5;
    }
  }

  @media (max-width: 400px) and (max-height: 600px) {
    input {
      margin-top: 40px;
    }
  }

  @media (min-width: ${breakpoints.md})  {
    input {
      margin-top: 80px;
    }
  }
  @media (min-width: ${breakpoints.md}) and (min-height: 800px) {
    input {
      margin-top: 110px;
    }
  }
`

const SubText = styled.div`
  margin: 15px auto 0;
  font-size: 14px;
  line-height: 1.38;
  max-width: 220px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 25px;
  }
`
const WhyWeAsk = styled.span`
  a{
    vertical-align: middle;
    color: white;
    font-size: 18px;
  }
  img{
    width: 16px;
  }
`

const StyledReactTooltip = styled(ReactTooltip)`
  @media (max-width: ${breakpoints.md}) {
    position: absolute !important;
    left: 15% !important;
    width: 70% !important;
  }
`

const TextInput = (props) => {
  const {
    inputIdentifier,
    inputPlaceholderText,
    subtext,
    dynamicQuestion,
    trackingLabel,
    quizAnswers,
    setQuizAnswers,
    handleNext,
    active
  } = props

  const [value, setValue] = useState("")
  const [enter, setEnter] = useState(false)
  let sectionRef = useRef(null)
  const isEmail = trackingLabel === "Email"

  useEffect(() => {
    if (quizAnswers[trackingLabel]) {
      setValue(quizAnswers[trackingLabel])
    }
  }, [])

  const handleAnswerUpdate = (e) => {
    let answers = { ...quizAnswers }
    answers[trackingLabel] = e.target.value
    setValue(e.target.value)
    setQuizAnswers(answers)
  }

  const checkEnterKey = (e) => {
    e.preventDefault()
    if (e.keyCode === 13) {
      if (!enter) {
        setEnter(true)
      }
    }
  }

  useEffect(() => {
    if (sectionRef) {
      sectionRef.addEventListener("keyup", checkEnterKey)
    }

    return () => {
      if (sectionRef) {
        sectionRef.removeEventListner("keyup", checkEnterKey)
      }
    }
  })

  useEffect(() => {
    if (enter) {
      handleNext(false, isEmail, trackingLabel,inputIdentifier)
    }
  }, [enter])


  return (
    <React.Fragment>
      <TextInputContainer active={value !== "" ? 1 : 0} ref={(el) => (sectionRef = el)}>
        <Question>
          {dynamicQuestion}
          {props.whyWeAsk && props.whyWeAsk.childMarkdownRemark.html !== "" && (
              <WhyWeAsk>
                <a data-tip={props.whyWeAsk.childMarkdownRemark.html}> <img src={QuestionIcon} /> </a>
                <StyledReactTooltip place="bottom" type="dark" effect="solid" html={true} />
              </WhyWeAsk>
            )}
        </Question>
        {subtext && <SubText>{subtext}</SubText>}
        <input
          type={isEmail ? "email" : "text"}
          name={inputIdentifier}
          placeholder={inputPlaceholderText}
          onChange={(e) => handleAnswerUpdate(e)}
          value={value}
        />
      </TextInputContainer>
      <ButtonBar {...props} skip={null}/>
    </React.Fragment>
  )
}

export default TextInput
