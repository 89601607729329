import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import QuizContentContainer from "./QuizContentContainer"
import TextInput from "./TextInput"
import MultiChoiceInput from "./MultiChoiceInput"
import SingleChoiceInput from "./SingleChoiceInput"
import { isBrowser } from "../../services/core/browser"
import { lambdaCall } from "../../services/core/lambdaCall"
import id from "../../services/tracking/id"
import { cvgTrack } from "../../services/tracking/converge"
import "../../styles/general.css"

const TopicContainer = styled.div`
  text-align: center;
  display: ${(props) => (props.visible ? "block" : "none")};
  overflow: scroll;
  height: 100%;
  padding-bottom: 100px;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  ::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 0;
  }
`

const Section = styled.div`
  display: ${(props) => (props.visible ? "block" : "none")};
`

const TrackingLabel = styled.div`
  ${fonts.sofiaProBold};
  font-size: 12px;
  line-height: 1.07;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const TopicSections = styled.div``

const TopicContentContainer = styled.div``

const QuizTopic = (props) => {
  const {
    sections,
    quizIndex,
    setQuizIndex,
    quizAnswers,
    setQuizAnswers,
    title,
    handleOverlayIndex,
    setSectionIndex,
    sectionIndex,
    topics,
    visible,
    quizFlow,
    setQuizFlow,
  } = props

  const [skipTo, setSkipTo] = useState(null)
  const [contentActive, setContentActive] = useState(false)

  const scrollToTop = () => {
    if (isBrowser()) {
      let global = document.getElementById("global")
      if (global) {
        global.scrollTo(0, 0)
      }
    }
  }

  const handleNext = (e, isEmail = false, trackingLabel, identifier, skip) => {
    if (e) {
      e.preventDefault()
    }

    scrollToTop()
    setContentActive(false)
    let nextTopicIndex
    let nextSectionIndex
    let isQuizComplete = false
    let flow = { identifier, sectionIndex, quizIndex, skip }

    setQuizFlow([...quizFlow, flow])

    if (skip) {
      if (skip === "thank_you") {
        isQuizComplete = true
        setQuizIndex(topics.length + 1)
        setSectionIndex(null)
        handleOverlayIndex()
      } else {
        topics.forEach((topic, index) => {
          let hasSection = topic.sections.find(
            (section) =>
              section.selectionsIdentifier === skip ||
              section.inputIdentifier === skip
          )
          let section = topic.sections.findIndex(
            (section) =>
              section.selectionsIdentifier === skip ||
              section.inputIdentifier === skip
          )

          if (hasSection) {
            nextTopicIndex = index + 1
            nextSectionIndex = section
          }
        })
        if (
          nextTopicIndex !== "undefined" &&
          nextSectionIndex !== "undefined"
        ) {
          setQuizIndex(nextTopicIndex)
          setSectionIndex(nextSectionIndex)
        }
      }
    } else {
      setSkipTo(null)
      if (sectionIndex === sections.length - 1) {
        nextTopicIndex = quizIndex + 1
        handleOverlayIndex()
        if (nextTopicIndex > topics.length) {
          setQuizIndex(quizIndex + 1)
          setSectionIndex(null)
          isQuizComplete = true
        } else {
          setQuizIndex(quizIndex + 1)
          setSectionIndex(0)
        }
      } else {
        setSectionIndex(sectionIndex + 1)
      }
    }
    if (isEmail) {
      const body = JSON.stringify({
        email: quizAnswers["Email"],
        name: quizAnswers["Name"],
      })
      lambdaCall({
        url: "quiz",
        method: "POST",
        body: body,
      })
        .then((response) => {
          return Promise.all([response.status, response.text()])
        })
    }
    if (!isQuizComplete) {
      if (isBrowser()) {
        const dataLayer = window.dataLayer
        if (typeof dataLayer !== "undefined") {
          cvgTrack({
            eventName: 'Completed Quiz Step',
            profileProperties: isEmail ? {
              $email: quizAnswers["Email"]
            } : undefined,
            properties: { label: trackingLabel }
          })
          dataLayer.push({
            event: "dl_quiz_steps",
            event_id: id.quizStepsEventId(),
            quiz: {
              label: trackingLabel,
            },
          })
          if (isEmail) {
            dataLayer.push({
              event: "dl_user_data",
              event_id: id.userDataEventId(),
              user_properties: {
                customer_email: quizAnswers["Email"],
              },
            })
          }
        }
      }
    }
  }

  const handlePrevious = () => {
    let flow = [...quizFlow]
    let updatedFlow = flow.slice(0, flow.length - 1)

    scrollToTop()

    if (quizFlow.length > 0) {
      setQuizIndex(quizFlow[quizFlow.length - 1].quizIndex)
      setSectionIndex(quizFlow[quizFlow.length - 1].sectionIndex)
    } else {
      setQuizIndex(0)
      setSectionIndex(0)
    }
    setQuizFlow(updatedFlow)
  }

  const renderSection = (section, index) => {
    const { __typename: type, question } = section
    let name = quizAnswers["Name"]
    let dynamicQuestion = question
    let active = visible && sectionIndex === index
    if (name) {
      dynamicQuestion = question?.replace("[NAME]", name)
    }

    if (type === "ContentfulTextInput") {
      return (
        <TextInput
          {...section}
          {...props}
          dynamicQuestion={dynamicQuestion}
          quizAnswers={quizAnswers}
          setQuizAnswers={setQuizAnswers}
          handleNext={handleNext}
          handlePrevious={handlePrevious}
          active={active}
        />
      )
    }

    if (type === "ContentfulQuizChoiceInput") {
      const isRadio = section.type ? true : false
      if (isRadio) {
        return (
          <SingleChoiceInput
            {...section}
            {...props}
            dynamicQuestion={dynamicQuestion}
            quizAnswers={quizAnswers}
            setQuizAnswers={setQuizAnswers}
            skipTo={skipTo}
            setSkipTo={setSkipTo}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            active={active}
          />
        )
      } else {
        return (
          <MultiChoiceInput
            {...section}
            {...props}
            dynamicQuestion={dynamicQuestion}
            quizAnswers={quizAnswers}
            setQuizAnswers={setQuizAnswers}
            skipTo={skipTo}
            setSkipTo={setSkipTo}
            handleNext={handleNext}
            handlePrevious={handlePrevious}
            active={active}
          />
        )
      }
    }
  }

  const handleHtmlTextLink = (html) => {
    let htmlText = html
    if (html.includes("<a href")) {
      htmlText = html.replace("<a href", `<a target="_blank" href`)
    }

    return htmlText
  }
  const handleActiveContent = () => {
    setContentActive(!contentActive)
  }
  return (
    <TopicContainer visible={visible ? 1 : 0}>
      {sections.map((section, index) => {
        const { whyWeAsk } = section
        let visible = sectionIndex === index
        return (
          <Section visible={visible ? 1 : 0} key={index}>
            <QuizContentContainer>
              <TopicContentContainer>
                <TrackingLabel>{title}</TrackingLabel>
                <TopicSections>{renderSection(section, index)}</TopicSections>
              </TopicContentContainer>
            </QuizContentContainer>
          </Section>
        )
      })}
    </TopicContainer>
  )
}

export default QuizTopic
