import React from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import Lottie from "lottie-react-web"
import { renderRichText } from "gatsby-source-contentful/rich-text"

const Overlay = styled.div`
  background: ${colors.vanilla};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: ${(props) => (props.first ? 11 : 10)};
  pointer-events: none;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`

const Copy = styled.div`
  width: 100%;
  margin: auto;
  margin-top: 140px;
  padding: 0 20px;
  text-align: center;
  ${fonts.garamondRegular};
  font-size: 20px;
  line-height: 1.36;
  color: ${colors.charcoal};
  max-width: 620px;
  z-index: 1;
  p {
    margin-bottom: 12px;
  }
  @media (max-height: 700px) {
    margin-top: 100px;
  } 

  @media (min-width: ${breakpoints.md}) {
    font-size: 22px;
    line-height: 1.3;
    p {
      margin-bottom: 20px;
    }
  }
`

const Anim = styled.div`
  width: 100%;
  position: absolute;
  height: ${props => props.last ? "auto" : "100%"};
  bottom: ${props => props.last && "25vh"};

  @media (max-height: 600px) {
    bottom: ${props => props.last && "20vh"};
  }
`

const OverlayAnimation = (props) => {
  const {
    animations,
    visible,
    first,
    setOverlayVisible,
    handleSubmit,
    width,
    overlayIndex,
    resultCopy,
    isLastIndex,
  } = props

  const handleAnimationComplete = () => {
    if (isLastIndex) {
      handleSubmit()
    } else {
      setOverlayVisible(false)
    }
  }
  
  return (
    <Overlay visible={visible ? 1 : 0} first={first ? 1 : 0}>
      {animations.map((animation, index) => {
        let version = width >= 768 ? "desktop" : "mobile"
        return (
          index === overlayIndex && (
            <React.Fragment key={index}>
              {index === animations.length - 1 && (
                <Copy>{renderRichText(resultCopy)}</Copy>
              )}
              <Anim last={index === animations.length - 1 ? 1 : 0}>
                <Lottie
                  options={{
                    animationData: animation[version],
                    loop: false,
                  }}
                  eventListeners={[
                    {
                      eventName: "complete",
                      callback: () => handleAnimationComplete(),
                    },
                  ]}
                />
              </Anim>
            </React.Fragment>
          )
        )
      })}
    </Overlay>
  )
}

export default OverlayAnimation
