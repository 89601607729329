import React from "react"
import styled from "styled-components"
import colors from "../../styles/colors"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import OnClickButton from "../Core/Buttons/OnClickButton"
import Button from "../Core/Buttons/Button"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import FocusTrap from "focus-trap-react"

const Overlay = styled.div`
  position: fixed;
  background: rgba(186, 180, 217, 0.6);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  transition: all 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  z-index: 12;
  opacity: ${props => props.open? 1 : 0};
  pointer-events: ${props => props.open ? "auto" : "none"};
`

const Content = styled.div`
  padding: 45px 20px;
  max-width: 600px;
  min-height: 285px;
  background: ${colors.white};

  @media (min-width: ${breakpoints.md}) {
    padding: 40px;
    max-width: 620px;
  }
`

const ContentCopy = styled.div`
  ${fonts.sofiaPro};
  font-size: 14px;
  line-height: 1.38;
  color: ${colors.charcoal};

  a {
    color: ${colors.charcoal};
  }

  b {
    ${fonts.sofiaPro};
  }

  i {
    font-style: italic;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 45px;
  display: grid;
  grid-gap: 20px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 35px;
  }
`

const SpeedBumpPopup = (props) => {
  const { copy, accept, exit, open, handlePopup } = props
  return (
    <FocusTrap active={open}>
      <Overlay open={open ? 1 : 0}>
        <Content>
          <ContentCopy>{renderRichText(copy)}</ContentCopy>
          <ButtonsContainer>
            <OnClickButton onClick={() => handlePopup(false)}>{accept}</OnClickButton>
            <Button href={"/"}>{exit}</Button>
          </ButtonsContainer>
        </Content>
      </Overlay>
    </FocusTrap>
  )
}

export default SpeedBumpPopup
