import React from "react"
import styled from "styled-components"
import { ButtonStyle } from "./Styles"
import PropTypes from "prop-types"

const ButtonContainer = styled.div`
  ${ButtonStyle};
`

const OnClickButton = ({ onClick, children, label }) => {
  return <ButtonContainer onClick={onClick} onKeyPress={onClick} tabIndex="0" role="button" aria-label={label}>{children}</ButtonContainer>
}

OnClickButton.propTypes = {
  onClick: PropTypes.func.isRequired,
}

export default OnClickButton
