import React, { useState, useRef, useEffect } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import breakpoints from "../../styles/breakpoints"
import ButtonBar from "./ButtonBar"
import Option from "./Option"
import QuestionIcon from "../../resources/img/ui/icon-question-mark.svg"
import ReactTooltip from 'react-tooltip';

const Question = styled.div`
  ${fonts.garamondRegular};
  margin: 20px auto 0;
  font-size: 20px;
  line-height: 1.36;
  max-width: 600px;
  p{

  }
  @media (min-width: ${breakpoints.sm}) {
    margin-top: 30px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 30px;
    font-size: 22px;
  }
`

const ChoiceInputContainer = styled.div``

const Options = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: ${(props) => (props.subtext ? "30px" : "45px")};
  @media (max-width: 400px) and (max-height: 600px) {
    margin-top: 20px;
  }
  @media (min-width: ${breakpoints.md}) {
    margin-top: 30px;
  }
  @media (min-width: ${breakpoints.md}) and (min-height: 800px) {
    margin-top: 60px;
  }
`

const SubText = styled.div`
  margin: 15px auto 0;
  font-size: 14px;
  line-height: 1.38;
  max-width: 220px;

  @media (min-width: ${breakpoints.md}) {
    margin-top: 25px;
  }
`
const WhyWeAsk = styled.span`
  a{
    vertical-align: middle;
    font-size: 18px;
  }
  img{
    width: 16px;
  }
`

const StyledReactTooltip = styled(ReactTooltip)`
  @media (max-width: ${breakpoints.md}) {
    position: absolute !important;
    left: 15% !important;
    width: 70% !important;
  }
`

const ChoiceInput = (props) => {
  const {
    options,
    subtext,
    quizAnswers,
    setQuizAnswers,
    trackingLabel,
    selectionsIdentifier,
    dynamicQuestion,
    handleNext,
    questionWeight,
    active,
  } = props

  const [popupOpen, setPopupOpen] = useState(false)
  const [enter, setEnter] = useState(false)
  const [skipTo, setSkipTo] = useState(null)
  const skipToWeightChart = {}

  options.forEach((option) => {
    if (option.skipToQuestion) {
      let chartKeys = Object.keys(skipToWeightChart)
      if (!chartKeys.includes(option.skipToQuestion)) {
        skipToWeightChart[option.skipToQuestion] =
          option.skipToQuestionWeight || 0
      }
    }
  })

  let sectionRef = useRef(null)

  const handleAnswerUpdate = (value, skip, speedBump) => {
    let answers = { ...quizAnswers }

    answers[trackingLabel] = value

    if (skip && skip !== skipTo) {
      setSkipTo(skip.trim())
    } else {
      setSkipTo(null)
    }

    if (speedBump) {
      setPopupOpen(true)
    }
    setQuizAnswers(answers)
  }

  const checkEnterKey = (e) => {
    e.preventDefault()
    if (e.keyCode === 13) {
      if (!enter) {
        setEnter(true)
      }
    }
  }

  useEffect(() => {
    if (sectionRef) {
      sectionRef.addEventListener("keyup", checkEnterKey)
    }

    return () => {
      if (sectionRef) {
        sectionRef.removeEventListner("keyup", checkEnterKey)
      }
    }
  })

  useEffect(() => {
    if (enter) {
      const isEmail = trackingLabel === "Email"
      handleNext(false, isEmail, trackingLabel, selectionsIdentifier)
    }
  }, [enter])

  return (
    <React.Fragment>
      <ChoiceInputContainer ref={(el) => (sectionRef = el)}>
        <Question>
          <p>
            {dynamicQuestion}
        {props.whyWeAsk && props.whyWeAsk.childMarkdownRemark.html !== "" && (
          <WhyWeAsk>
            <a data-tip={props.whyWeAsk.childMarkdownRemark.html}> <img src={QuestionIcon} /> </a>
            <StyledReactTooltip place="bottom" type="dark" effect="solid" html={true} />
          </WhyWeAsk>
        )}
          </p>
        </Question>
        {subtext && <SubText><p>{subtext}</p></SubText>}
        <Options subtext={subtext ? 1 : 0}>
          {options.map((option, index) => {
            const { text, speedBumpText, speedBumpExit, speedBumpAccept } =
              option
            const value = text.split(" ").join("-").toLowerCase()
            const speedBump = speedBumpAccept && speedBumpExit && speedBumpText
            const isChecked =
              quizAnswers[trackingLabel] && quizAnswers[trackingLabel] === value
            return (
              <Option
                {...option}
                value={value}
                selectionsIdentifier={selectionsIdentifier}
                speedBump={speedBump}
                isChecked={isChecked}
                key={index}
                handleAnswerUpdate={handleAnswerUpdate}
                questionWeight={questionWeight}
                popupOpen={popupOpen}
                setPopupOpen={setPopupOpen}
              />
            )
          })}
        </Options>
      </ChoiceInputContainer>
      <ButtonBar {...props} skip={skipTo} submit={selectionsIdentifier === "flake_occurrence"} />
    </React.Fragment>
  )
}

export default ChoiceInput
