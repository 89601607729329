import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"
import breakpoints from "../../styles/breakpoints"
import Chevron from "../../resources/img/ui/icon-chevron.svg"
import colors from "../../styles/colors"
import { isBrowser } from "../../services/core/browser"

const StyledContainer = styled.div`
  padding: 100px 20px 80px;

  @media (min-width: ${breakpoints.sm}) {
    padding: 100px 60px 80px;
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 100px 60px 280px;
  }

  @media (min-width: ${breakpoints.md}) and (min-height: 670px) {
    padding: 120px 60px 280px;
  }

  @media (min-width: ${breakpoints.md}) and (min-height: 800px) {
    padding: 120px 60px 370px;
  }
`

const Arrow = styled.div`
  position: fixed;
  bottom: 80px;
  background: ${colors.white};
  -webkit-box-shadow: 0px 1px 7px 1px rgba(128, 128, 128, 0.4);
  box-shadow: 0px 1px 7px 1px rgba(128, 128, 128, 0.4);
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) => (props.visible ? 1 : 0)};
  transition: opacity 0.3s ease-out;
  z-index: 2;
  cursor: pointer;
  img {
    max-width: 13px;
  }

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const QuizContentContainer = ({ children }) => {
  const [overflow, setOverflow] = useState(false)
  let containerRef = useRef(null)

  const handleScroll = () => {
    if (isBrowser()) {
      const global = document.getElementById("global")
      const windowHeight = window.innerHeight - 180 //padding;
      let contentHeight
      let scrollTop
      if (global) {
        scrollTop = global.scrollTop
      }
      if (containerRef) {
        contentHeight = containerRef.scrollHeight - 200 //padding
      }
      if (windowHeight < contentHeight - scrollTop) {
        setOverflow(true)
      } else {
        setOverflow(false)
      }
    }
  }

  useEffect(() => {
    if (isBrowser()) {
      const global = document.getElementById("global")
      handleScroll()
      if (global) {
        global.addEventListener("scroll", handleScroll)
        return () => {
          global.removeEventListener("scroll", handleScroll)
        }
      }
    }
  })

  const handleClick = () => {
    if (containerRef) {
      containerRef.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  }

  return (
    <StyledContainer ref={(el) => (containerRef = el)}>
      {children}
      <Arrow visible={overflow ? 1 : 0} onClick={() => handleClick()} onKeyDown={() => handleClick} role="button" tabIndex="0">
        <img src={Chevron} alt="more" />
      </Arrow>
    </StyledContainer>
  )
}

export default QuizContentContainer
