import React from "react"
import styled from "styled-components"
import { ButtonStyle } from "./Styles"
import { Link } from "gatsby"

const StyledLink = styled(Link)`
  ${ButtonStyle}
`

const StyledA = styled.a`
  ${ButtonStyle}
`

const Button = ({ href, to, children, transparent }) => {
  return to ? (
    <StyledLink to={to}>{children}</StyledLink>
  ) : (
    <StyledA href={href}>{children}</StyledA>
  )
}

export default Button
