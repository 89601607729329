import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import QuizContentContainer from "./QuizContentContainer"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import OnClickButtonDark from "../Core/Buttons/OnClickButtonDark"
import { isBrowser } from "../../services/core/browser"
import id from "../../services/tracking/id"
import { cvgTrack } from "../../services/tracking/converge"

const IntroContainer = styled.div`
  text-align: center;
  padding-bottom: 100px;

  @media (min-width: ${breakpoints.md}) {
    padding-bottom: 0;
  }
`

const IntroContentContainer = styled.div`
  @media (min-width: ${breakpoints.md}) {
    max-width: 600px;
    margin: auto;
  }
`

const TrackingLabel = styled.div`
  ${fonts.sofiaProBold};
  font-size: 12px;
  line-height: 1.07;
  letter-spacing: 1px;
  text-transform: uppercase;

  @media (min-width: ${breakpoints.md}) {
    display: none;
  }
`

const IntroContent = styled.div`
  margin-top: 20px;
  ${fonts.garamondRegular};
  b {
    ${fonts.sofiaProBold};
  }
  p:first-child{
    font-weight: 800;
  }
  p {
    font-size: 20px;
    line-height: 1.36;
    margin-bottom: 12px;
    :last-of-type {
      margin-bottom: 0;
    }
  }

  @media (min-width: ${breakpoints.md}) {
    margin-top: 30px;
    p {
      font-size: 22px;
      line-height: 1.36;
    }
  }

  @media (max-height: 700px) {
    p {
      margin-bottom: 6px;
      :last-of-type {
        margin-bottom: 0;
      }
    }
  }
`

const DoctorCard = styled.div`
  margin-top: 50px;
  @media (min-width: ${breakpoints.md}) {
    margin-top: 20px;
  }

  @media (max-height: 700px) {
    margin-top: 10px;
  }
`

const DoctorImage = styled(GatsbyImage)`
  width: 80px;
  height: 80px;
  border-radius: 50%;
`

const DoctorName = styled.div`
  ${fonts.sofiaProBold};
  font-size: 13px;
  line-height: 1.15;
  margin-top: 15px;
`

const DisclaimerContainer = styled.div`
  margin: 55px auto 30px;
  display: ${(props) => (props.mobile ? "block" : "none")};
  max-width: 390px;
  @media (min-width: ${breakpoints.md}) {
    display: ${(props) => (props.desktop ? "block" : "none")};
  }

  @media (min-width: ${breakpoints.md}) and (min-height: 800px) {
    margin-top: 95px;
  }

  @media (max-height: 750px) {
    margin-top: 32px;
  }

  @media (max-height: 650px) {
    margin-top: 20px;
  }
`

const DisclaimerHeadline = styled.div`
  ${fonts.garamondBoldItalic};
  font-size: 16px;
  line-height: 1.2;
  margin-bottom: 3px;
`

const DisclaimerCopy = styled.div`
  font-size: 14px;
  line-height: 1.38;
`

const StepButtonContainer = styled.div`
  width: 90%;
  padding: 0 5px 5px;
  margin:auto;
  div {
    max-width: unset;
  }

  @media (min-width: ${breakpoints.sm}) {
    div {
      max-width: 280px;
    }
  }
  @media (min-width: ${breakpoints.md}) {
    position: relative;
    margin-top: 0;
    div {
      max-width: 260px;
    }
  }
`

const ScrollContainer = styled.div`
  height: 100%;
`

const BottomBar = styled.div`
  height: 80px;
  position: fixed;
  bottom: 0;
  background: ${colors.vanilla};
  width: 100%;
  left: 0;
  display: flex;
  align-items: flex-end;

  @media (min-width: ${breakpoints.air}) {
    display: block;
    height: 280px;
    padding-top: 30px;
    margin-top: 40px; 
    position: inherit;
  }

  @media (min-width: ${breakpoints.md}) and (min-height: 799px) {
    height: 300px;
    padding-top: 0;
  }

  @media (max-height: 750px) {
    padding-top: 0;
  }

  @media (max-height: 650px) {
    margin-top: 20px;
  }
`
const Intro = (props) => {
  const {
    introCopy,
    introTrackingLabel,
    quizStartButtonText,
    disclaimerCopy,
    disclaimerHeadline,
    doctor,
    setQuizIndex,
  } = props
let disclamerText = 'This will take 1-2 minutes'
  const initiateQuizStart = () => {
    if (isBrowser()) {
      cvgTrack({ eventName: 'Started Quiz' })
      const dataLayer = window.dataLayer
      const eventId = id.quizStartEventId()
      if (typeof dataLayer !== "undefined") {
        dataLayer.push({
          event: "dl_quiz_start",
          event_id: eventId
        })
      }
    }
    setQuizIndex(1)
  }

  return (
    <IntroContainer id={"intro"}>
      <QuizContentContainer>
        <IntroContentContainer>
          <ScrollContainer>
            <TrackingLabel>{introTrackingLabel}</TrackingLabel>
            {doctor && (
              <DoctorCard>
                <DoctorImage
                  image={getImage(doctor.image)}
                  alt={doctor.image.title || ""}
                />
                <DoctorName>{renderRichText(doctor.content)}</DoctorName>
              </DoctorCard>
            )}
            <IntroContent>{renderRichText(introCopy)}</IntroContent>
       
            <DisclaimerContainer mobile>
              <DisclaimerHeadline>{disclaimerHeadline}</DisclaimerHeadline>
              <DisclaimerCopy>{disclaimerCopy}</DisclaimerCopy>
            </DisclaimerContainer>
            <BottomBar>
              <StepButtonContainer>
                <OnClickButtonDark onClick={() => initiateQuizStart()} aria-label="Start Quiz">
                  {quizStartButtonText}
                </OnClickButtonDark>
              </StepButtonContainer>
              <DisclaimerContainer desktop>
                <DisclaimerHeadline>{disclaimerHeadline}</DisclaimerHeadline>
                <DisclaimerCopy>{disclaimerCopy}</DisclaimerCopy>
              </DisclaimerContainer>
            </BottomBar>
          </ScrollContainer>
        </IntroContentContainer>
      </QuizContentContainer>
    </IntroContainer>
  )
}

export default Intro
