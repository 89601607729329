import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import { ButtonStyleDark } from "../Core/Buttons/Styles"
import "../../styles/general.css"

const StepButtonContainer = styled.div`
  margin: 0 auto;
  width: 100%;
  padding: 0 20px 5px;
  display: flex;
  max-width: 384px;
  div {
    background: ${(props) => props.disabled && "#f7f5f0"};
  }
`

const PrevButton = styled.div`
  ${fonts.sofiaProBold};
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-right: 30px;
  font-size: 13px;
  line-height: 0.9;
  cursor: pointer;
  position:relative;
  &:before{
    content:'';
    display: block;
    width: 100%;
    height: 2px;
    background:#2F3035;
    position: absolute;
    bottom:20%;
  }
  img {
    width: 15px;
    margin-right: 8px;
  }
`

const NextButton = styled.div`
  ${ButtonStyleDark};
  background: ${(props) => (props.disabled ? "#8c9aa1" : "auto")};
  border: ${(props) => (props.disabled ? "1px solid #8c9aa1" : "auto")};
  :hover {
    color: ${(props) => (props.disabled ? colors.white : "auto")};
    background: ${(props) => (props.disabled ? "#8c9aa1" : "auto")};
    border: ${(props) => (props.disabled ? "1px solid #8c9aa1" : "auto")};
  }
`

const WhyWeAsk = styled.div`
  padding: 15px 30px;
  margin: 50px auto 30px;
  color: ${colors.charcoal};
  font-size: 12px;
  line-height: 1.3;
  max-width: 540px;
  a {
    color: inherit;
  }
  display: ${(props) => (props.mobile ? "block" : "none")};
  @media (min-width: ${breakpoints.md}) {
    display: ${(props) => (props.desktop ? "block" : "none")};
    margin: 16px auto 0;
  }
  @media (min-width: ${breakpoints.md}) and (min-height: 800px) {
    margin: 56px auto 0;
  }
`

const WhyWeAskTitle = styled.div`
  ${fonts.garamondBoldItalic};
  font-size: 16px;
  line-height: 1.2;
  color: ${colors.charcoal};
  margin-bottom: 3px;
  display:flex;
  align-items:center;
  justify-content:center;
  gap: 10px;
  cursor:pointer;
  img{
    width:15px;
    height:15px;
    margin-top:-2px;
   
  }
`
const WhyWeAskContent = styled.div`
  p{
  
  transition: visibility 100ms linear 300ms, opacity 400ms, height 400ms;
  height: ${(props) => (props.active ? '60px' : '0px' )}; 
  visibility: ${(props) => (props.active ? 'visible' : 'hidden' )};
  opacity: ${(props) => (props.active ? '1' : '0' )};
  }
`

const BottomBar = styled.div`
  height: 80px;
  position: fixed;
  bottom: 0;
  background: ${colors.vanilla};
  width: 100%;
  left: 0;
  display: flex;
  align-items: flex-end;

  @media (min-width: ${breakpoints.md}) {
    display: block;
    height: 280px;
    padding-top: 30px;
  }

  @media (min-width: ${breakpoints.md}) and (min-height: 800px) {
    height: 370px;
  }
`

const ButtonBar = (props) => {
  const {whyWeAsk, trackingLabel, quizAnswers, handleNext, handlePrevious, inputIdentifier, selectionsIdentifier, skip, submit = false } = props;

  const [contentActive, setContentActive] = useState(false);
  let isEmail = trackingLabel === "Email"
  const identifier =inputIdentifier || selectionsIdentifier
  const disabled =
    (!quizAnswers[trackingLabel] ||
      quizAnswers[trackingLabel] === "" ||
      (Array.isArray(quizAnswers[trackingLabel]) &&
        quizAnswers[trackingLabel].length === 0))
  const handleActiveContent = () => {
    setContentActive(!contentActive)
  }


  return (
    <BottomBar>
    <StepButtonContainer>
      <PrevButton onClick={() => handlePrevious()} onKeyPress={() => handlePrevious()} role="button" tabIndex="0" aria-label="Go to previous question">
        
       Back
      </PrevButton>
      <NextButton
        onClick={(e) =>
          {
          !disabled && handleNext(e, isEmail, trackingLabel, identifier, skip);
          !disabled && setContentActive(false)
          }
        }
        onKeyPress={(e) =>
          !disabled &&
          handleNext(e, isEmail, trackingLabel, identifier, skip)
        }
        role="button"
        tabIndex="0"
        aria-label="Go to next question"
        disabled={disabled ? 1 : 0}
        id={`button-${identifier}`}
      >
        {submit ? "Submit" : "Next"}
      </NextButton>
    </StepButtonContainer>
  </BottomBar>
  )
}

export default ButtonBar
