import React, { useState } from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"
import SpeedBumpPopup from "./SpeedBumpPopup"
/*
  border: ${(props) =>
    props.checked ? `1px solid ${colors.charcoal}` : `1px solid transparent`};

*/ 
const OptionContainer = styled.div`
  margin: 0 8px 16px;
  position: relative;
  width: 100%;
  height: inherit;
  background: ${colors.white};
  background: ${(props) =>
    props.checked ? `#DAD5EF` : `${colors.white}`};
  width: 150px;
  min-height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  border:3px solid;

  transition: all 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
  label {
    ${(props) => (props.checked || props.hover ? fonts.sofiaProBold : fonts.sofiaPro)};
    font-size: 13px;
    opacity: ${(props) => (props.checked ? 1 : 0.7)};
    padding: 10px 3px;
    text-align: center;
    width: 100%;
    height: inherit;
    margin-bottom: 2px;
  }

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    margin: 0;
    z-index: 1;
  }

  @media (max-width: 400px) and (max-height: 600px) {
    width: 100%;
    max-width: calc(50vw - 40px);
    min-height: 60px;
  }

  @media (min-width: ${breakpoints.md}) {
    width: 140px;
    min-height: 50px;
    margin: 0 5px 10px;
    border: ${props => props.checked ? `1px solid ${colors.charcoal}` : "1px solid transparent"};
    label {
      opacity: ${props => props.checked? 1 : 0.7};
    }

    :hover {
      border: 1px solid ${colors.charcoal};
      label {
        ${fonts.sofiaProBold};
        opacity: 1;
      }

    }
  }
`

const Icon = styled.img`
  margin: auto;
  margin-bottom: 15px;
  width: 28px;
  min-height: 50px;
  display: block;
`

const Option = (props) => {
  const {
    setRecommendation,
    questionWeight,
    isChecked,
    selectionsIdentifier,
    value,
    handleAnswerUpdate,
    popupOpen,
    speedBumpText,
    speedBumpAccept,
    speedBumpExit,
    setPopupOpen,
    skipToQuestion,
    speedBump,
    text,
    icon,
    id
  } = props

  const [hover , setHover] = useState(false)

  return (
    <OptionContainer
      data-recommendation={setRecommendation}
      data-weight={questionWeight}
      checked={isChecked ? 1 : 0}
      hover={hover ? 1 : 0}
    >
      <input
        type="checkbox"
        name={selectionsIdentifier}
        value={value}
        onChange={() => handleAnswerUpdate(value, skipToQuestion, speedBump)}
        onTouchStart={()=>  setHover(true)}
        onTouchEnd={() =>  setHover(false)}
        checked={isChecked ? true : false}
        id={id}
      />
      <label htmlFor={id}>
        {icon && <Icon src={icon.file.url} alt={text || ""} />}
        {text}
      </label>
      {speedBump && (
        <SpeedBumpPopup
          open={popupOpen}
          copy={speedBumpText}
          accept={speedBumpAccept}
          exit={speedBumpExit}
          handlePopup={setPopupOpen}
        />
      )}
    </OptionContainer>
  )
}

export default Option
