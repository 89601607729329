import React from "react"
import styled from "styled-components"
import fonts from "../../styles/fonts"
import colors from "../../styles/colors"
import breakpoints from "../../styles/breakpoints"

const ProgressBarContainer = styled.div`
  background: ${colors.vanilla};
  position: fixed;
  top: 50px;
  width: 100vw;
  z-index: 11;

  @media (min-width: ${breakpoints.md}) {
    top: 70px;
  }
`

const SectionsContainer = styled.div`
  max-width: 1160px;
  margin: auto;
  width: 90%;
  display: flex;
 
  & > div:first-child{
    border-top-left-radius:60px;
    border-bottom-left-radius:60px;
    span{
      border-top-left-radius:10px;
      border-bottom-left-radius:10px;
    }
  }
  & > div:last-child{
    border-top-right-radius:60px;
    border-bottom-right-radius:60px;
    span{
      border-top-right-radius:60px;
      border-bottom-right-radius:60px;
    }
  }
  @media (min-width: ${breakpoints.md}) {
    border-radius: 10px;
    overflow: hidden;
  }
`

const Section = styled.div`
  height: ${(props) => (props.mobileActive ? "8px" : "18px")};
  width: 100%;
  background: rgba(101, 82, 204, 0.2);
  margin-right: 0;
  position: relative;
  transition: height 0.4s ease-in;
  text-align: center;

  @media (min-width: ${breakpoints.md}) {
    height: 20px;
  }
`

const Fill = styled.span`
  position: absolute;
  height: 100%;
  left: 0%;
  width: ${(props) => props.width}%;
  background: ${colors.purple};
  transition: width 0.4s ease-in;
`

const SectionTitle = styled.span`
  ${fonts.sofiaProSemiBold};
  font-size: 10px;
  line-height: 0.9;
  letter-spacing: 1px;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: ${(props) => (props.mobileActive ? 0 : 1)};
  transition: opacity 0.4s ease-in;

  @media (min-width: ${breakpoints.md}) {
    opacity: 1;
  }
`

const ProgressBar = (props) => {
  const { blocks, sectionIndex, quizIndex } = props

  return (
    <ProgressBarContainer>
      <SectionsContainer>
        {blocks.map((block, index) => {
          const { sections } = block
          const isLast = index === blocks.length - 1
          const percentage =
            quizIndex > index + 1
              ? 100
              : index === quizIndex - 1
              ? Math.round(((sectionIndex + 1) * 100) / sections.length)
              : 0
          return (
            <Section
              last={isLast ? 1 : 0}
              key={index}
              mobileActive={quizIndex > 0 ? 1 : 0}
            >
              <Fill width={percentage} />
             
            </Section>
          )
        })}
      </SectionsContainer>
    </ProgressBarContainer>
  )
}

export default ProgressBar
